/* eslint-disable filenames/match-exported */
import * as Icon from "@heroicons/react/24/outline";
import { GetStaticProps } from "next";
import Image from "next/image";
import Link from "next/link";
import React, { ReactElement, useEffect, useState } from "react";

import styles from "./index.module.css";
import BlogPostCard from "../components/BlogPostCard";
import DownloadSection from "../components/DownloadSection";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NewsletterSection from "../components/NewsletterSection";
import { darkStyles, lightStyles } from "../components/StudioCTA";
import { AppEvent, useAnalytics } from "../hooks/useAnalytics";
import useToggle from "../hooks/useToggle";
import { CMS, Page } from "../lib/cms";

const cms = new CMS("customers");

const LOGOS = [
  "saronic.svg",
  "coco.webp",
  "wayve.svg",
  "dexterity.svg",
  "nvidia.webp",
  "waabi.svg",
];

type Props = {
  caseStudies: Page[];
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const caseStudies = (await cms.getAllPages())
    .sort((p1, p2) => ((p1.meta.subtitle ?? "") < (p2.meta.subtitle ?? "") ? -1 : 1))
    .slice(0, 3);
  return { props: { caseStudies } };
};

export default function Home({ caseStudies = [] }: { caseStudies?: Page[] }): ReactElement {
  const analytics = useAnalytics();
  const [showModal, toggleShowModal] = useToggle();
  const [primaryCTA, setPrimaryCTA] = useState<"signup" | "download">("signup");

  useEffect(() => {
    analytics.onFeatureFlags(() => {
      if (analytics.getFeatureFlag("homepage-cta") === "download") {
        setPrimaryCTA("download");
      }
    });
  }, [analytics]);

  return (
    <div>
      <Header shadow={false} pageTitle="Foxglove - Visualizing and debugging your robotics data" />
      <div className="relative overflow-hidden pb-8">
        <div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
          <div className="relative h-full">
            <svg
              className="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)" />
            </svg>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)" />
            </svg>
          </div>
        </div>

        <div className="relative">
          {/* Hero section */}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 py-14 md:py-16 lg:py-24">
            <div className="text-center">
              <div className="mb-8 flex justify-center">
                <div className="relative rounded-full px-4 py-2 text-sm leading-6 text-gray-600 bg-white ring-1 ring-purple-500 hover:ring-purple-700 hover:shadow-md hover:shadow-purple-100">
                  Join us for Actuate 2024 - a summit for robotics and embodied AI
                  developers.&nbsp;&nbsp;
                  <Link
                    href="https://actuate.foxglove.dev"
                    className="font-semibold text-indigo-600"
                  >
                    <span className="absolute inset-0" aria-hidden="true"></span>Register&nbsp;
                    <span aria-hidden="true">→</span>
                  </Link>
                </div>
              </div>
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-800 mx-auto sm:text-5xl md:text-6xl md:max-w-5xl">
                Visualization and observability
                <br />
                for <span className="text-color-primary">robotics developers</span>
              </h1>
              <div className="max-w-md md:max-w-3xl mx-auto mt-4 md:mt-8 text-base text-gray-600 sm:text-lg md:text-xl lg:text-2xl">
                <p>
                  Visualize, inspect, upload and organize multimodal data
                  <br /> to accelerate your robotics development.
                </p>
              </div>
              <div className="hidden md:flex justify-center">
                <div className="mt-5 sm:mt-8 sm:justify-center lg:justify-start sm:flex sm:space-x-4 space-y-2 sm:space-y-0 text-center">
                  {primaryCTA === "signup" ? (
                    <Link
                      href="https://app.foxglove.dev/signup"
                      className={`block justify-center px-6 py-3 border border-transparent text-base sm:text-xl font-medium rounded-md shadow-sm ${darkStyles}`}
                      onClick={(e) => {
                        e.preventDefault();
                        analytics.trackEvent(AppEvent.HOMEPAGE_GET_STARTED_CLICKED, undefined, {
                          send_instantly: true,
                        });

                        // wait 100ms before redirecting to allow the event to be sent
                        setTimeout(() => {
                          window.location.href = "https://app.foxglove.dev/signup";
                        }, 100);
                      }}
                    >
                      Get started
                      <span className="block text-xs mt-1">Sign up for a free account</span>
                    </Link>
                  ) : (
                    <Link
                      href="/download"
                      className={`block justify-center px-6 py-3 border border-transparent text-base sm:text-xl font-medium rounded-md shadow-sm ${darkStyles}`}
                      onClick={() => {
                        analytics.trackEvent(AppEvent.HOMEPAGE_DOWNLOAD_CLICKED, undefined, {
                          send_instantly: true,
                        });
                      }}
                    >
                      Download now
                      <span className="block text-xs mt-1">For Linux, Windows, and macOS</span>
                    </Link>
                  )}
                  <Link
                    href="/demo"
                    className={`block justify-center px-6 py-3 border border-transparent text-base sm:text-xl font-medium rounded-md shadow-sm ${lightStyles}`}
                    onClick={() => {
                      analytics.trackEvent(AppEvent.HOMEPAGE_GET_DEMO_CLICKED);
                    }}
                  >
                    Get a Demo
                    <span className="block text-xs mt-1">Schedule a call to learn more</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* App screenshot */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className={styles.heroImage}>
            <video
              className="relative rounded-lg shadow-lg"
              width="1400"
              height="800"
              loop
              autoPlay
              muted
              playsInline
            >
              <source src="https://assets.foxglove.dev/website/hero.webm" type="video/webm" />
              <source src="https://assets.foxglove.dev/website/hero.mp4" type="video/mp4" />
            </video>

            {showModal && (
              <div
                className="fixed z-10 inset-0 overflow-y-auto"
                aria-labelledby="demo-video"
                role="dialog"
                aria-modal="true"
              >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                    onClick={toggleShowModal}
                  ></div>
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>

                  <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
                    <div className="mt-3 text-center sm:mt-5">
                      <div className="relative rounded-lg shadow-lg">
                        <iframe
                          className="max-w-full max-h-full"
                          width="840"
                          height="472"
                          src="https://www.youtube.com/embed/ySVzQ3iFw90?autoplay=1"
                          title="YouTube video player – Getting Started with Foxglove"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <button onClick={toggleShowModal} className={styles.playDemoVideo}>
              Play demo video
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-4xl lg:max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 flex-col text-center">
        <h1 className="mb-8 text-2xl font-bold text-color-primary">
          Trusted by leading robotics teams
        </h1>
        <div className="flex flex-wrap justify-evenly">
          {LOGOS.map((logo) => (
            <div key={logo} className="justify-center self-center p-2">
              <img
                alt={`${logo} logo`}
                src={`/images/logos/${logo}`}
                style={{ width: "100%", maxWidth: "140px", maxHeight: "80px" }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Features */}
      <div className={styles.features}>
        <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48"></div>
        <div className={styles.feature}>
          <div className={styles.featureCols}>
            <div className={`${styles.featureColTextLeft ?? ""} lg:pt-10 lg:pb-40`}>
              <div>
                <div>
                  <span className={styles.featureIcon}>
                    <Icon.MapIcon />
                  </span>
                </div>
                <div className="mt-6">
                  <h2>
                    Use <span className="text-color-primary">rich interactive visualizations</span>{" "}
                    to analyze live or pre-recorded data.
                  </h2>
                  <p className={styles.featureDesc}>
                    Experience the world as your robot does. Visualize images and point clouds,
                    overlay bounding boxes, add classification labels and planned movements, and
                    drill down into your data with plots or raw message views.
                  </p>
                  <div className="mt-6">
                    <Link
                      href="https://app.foxglove.dev/signup"
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-color-secondary to-color-primary hover:from-color-secondary-hov hover:to-color-primary-hov"
                    >
                      Get started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.featureImageRight}>
              <div className={styles.imageWrapOuter}>
                <div className={styles.imageWrapInner}>
                  <img alt="Foxglove" width="720" height="480" src="/images/screenshot-ur5e.webp" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.feature ?? ""} mt-2`}>
          <div className={styles.featureCols}>
            <div className={`${styles.featureColTextRight ?? ""} lg:pt-20 lg:pb-48`}>
              <div>
                <div>
                  <span className={styles.featureIcon}>
                    <Icon.Squares2X2Icon />
                  </span>
                </div>
                <div className="mt-6">
                  <h2>
                    Organize and annotate data for{" "}
                    <span className="text-color-primary">efficient search and richer analysis</span>
                    .
                  </h2>
                  <p className={styles.featureDesc}>
                    Upload recordings to your private data lake for easy storage, searching, and
                    analysis. Stream and visualize data with a single click, to get instant insights
                    into your robots&apos; behavior.
                  </p>
                  <div className="mt-6">
                    <Link
                      href="https://app.foxglove.dev/signup"
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-color-secondary to-color-primary hover:from-color-secondary-hov hover:to-color-primary-hov"
                    >
                      Get started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.featureImageLeft}>
              <div className={styles.imageWrapOuter}>
                <div className={styles.imageWrapInner}>
                  <Image
                    width="720"
                    height="480"
                    src="/images/data-platform/timeline.webp"
                    alt="Web console"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative mt-2">
          <div className={styles.featureCols}>
            <div className={`${styles.featureColTextLeft ?? ""} lg:pt-20 lg:pb-32`}>
              <div>
                <div>
                  <span className={styles.featureIcon}>
                    <Icon.MapIcon />
                  </span>
                </div>
                <div className="mt-6">
                  <h2>Robust support to help you get the most out of Foxglove.</h2>
                  <p className={styles.featureDesc}>
                    Join our community, or sign up for a paid plan for commercial support.
                  </p>
                  <div className="mt-6">
                    <Link
                      href="/community"
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-color-secondary to-color-primary hover:from-color-secondary-hov hover:to-color-primary-hov"
                    >
                      Get involved
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.featureImageRight ?? ""} hidden sm:block`}>
              <div className={styles.imageWrapOuter}>
                <div className={styles.imageWrapInner}>
                  <Image
                    width="720"
                    height="480"
                    src="/images/screenshot-husky.webp"
                    alt="Foxglove screenshot"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Customers Section */}
      <div className="bg-gradient-to-r from-violet-800 to-indigo-700">
        <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-16 lg:max-w-7xl lg:pt-20 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white tracking-tight">Case studies</h2>
          <p className="mt-4 mb-12 max-w-3xl text-lg text-violet-200">
            Check out how our customers have accelerated development and reduced their time to
            market with Foxglove.
          </p>
          <div className={styles.caseStudiesGrid}>
            {caseStudies.map((post: Page, i: number) => (
              <BlogPostCard key={i} entity="customers" post={post} hideDetails={true} />
            ))}
          </div>
          <div className="mt-8 flex justify-end">
            <Link
              href="/customers"
              className="text-base font-medium text-white hover:color-secondary hover:underline"
            >
              View more...
            </Link>
          </div>
        </div>
      </div>

      <NewsletterSection hasBackground={false} />

      <DownloadSection />

      <Footer />
    </div>
  );
}
